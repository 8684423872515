"use strict";

var _interopRequireDefault = require("D:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
var _index = _interopRequireDefault(require("./Breadcrumb/index"));
var _index2 = _interopRequireDefault(require("./Hamburger/index"));
var _index3 = _interopRequireDefault(require("./Screenfull/index"));
var _index4 = _interopRequireDefault(require("./SvgIcon/index"));
var _FilterBox = _interopRequireDefault(require("./FilterBox"));
var _QuarterPicker = _interopRequireDefault(require("./QuarterPicker.vue"));
var _VueUeditorWrap = _interopRequireDefault(require("./VueUeditorWrap"));
// import DialogForm from './DialogForm'
// import MoreSelect from './MoreSelect'
// import DialogThreshold from './DialogThreshold'
// import SelectTabs from './SelectTabs'
// import InputDialog from './InputDialog'
// import CompanyDialog from './companyDialog'
// import VideoPlayer from './VideoPlayer'
// import ChooseField from './chooseField'
// import ChooseVariableGroupDialog from './chooseVariableGroupDialog'
// import EditorBar from './editorBar'
// import EditableCell from './EditableCell'
// import ValidatePassword from './ValidatePassword'
// import SelectSeedFieldId from './SelectSeedFieldId'
// import FaceList from './faceList.vue'

var component = {
  Breadcrumb: _index.default,
  Hamburger: _index2.default,
  Screenfull: _index3.default,
  SvgIcon: _index4.default,
  FilterBox: _FilterBox.default,
  QuarterPicker: _QuarterPicker.default,
  VueUeditorWrap: _VueUeditorWrap.default
};
var install = function install(Vue) {
  Object.keys(component).forEach(function (key) {
    Vue.component("".concat(key), component[key]);
  });
};
var _default = exports.default = Object.assign({}, component, {
  install: install
});